// Migrated
<template lang="pug">
.col(data-test-id="PackageSearchBar")
  .position-relative.w-100
    .charter-search.package-search.date-range.overflow-hidden(
      ref="searchcontainer"
      :class="{sv: sv}"
    )
      PackageSearchAirportButton(
        :is-active="activeSearch === 'airport'"
        :select-destination-first="selectDestinationFirst"
        :selected-airport="selectedAirport"
        @click="setActiveSearch('airport')"
      )

      PackageSearchDestinationButton(
        :is-active="activeSearch === 'destination'"
        :select-destination-first="selectDestinationFirst"
        @click="setActiveSearch('destination')"
      )

      PackageSearchDateRangeButton(
        :class="{ active: activeSearch === 'date', lastminute }",
        :is-active="activeSearch === 'date'",
        @click="setActiveSearch('date')"
        )
          template(#error-message)
            .charter-search__btn-title.text-error.blink.blink-title(v-if="selectDestinationFirst") {{ $t('charterSelectDestinationFirst') }}

      CharterSearchButtonGuests(
        v-if="!sv",
        ref="charterButtonGuests"
        :active="activeSearch === 'guests'"
        :display-rooms="peoplePerRoom"
        :select-child-ages="selectChildAges"
        @set-active="setActiveSearch('guests')",
      )

      .charter-search__btn-search(:class="{'pt-3 pt-lg-0': lastminute}")
        button.btn-orange.rounded-6.btn-lg.font-weight-medium(
          type="button"
          @click="search"
        )
          Loading(v-if="loading && isStartPage" charter-spinner parent-class="d-flex justify-content-center")
          span(v-else) {{ $t('searchButtonText') }}
    ScreenWidthProvider.position-absolute.w-100(
      v-slot="{ isNarrower }"
      :class="`package-search__dropdown-direction-${dropdownDirection}`"
    )
      CharterAirportSelect(
        key="charterWindowAirport"
        ref="charterWindowAirport"
        :active="activeSearch === 'airport'"
        :prefilled-selected-airport="safePrefilledSelectedAirport"
        :prefill="prefill"
        :selected-airport="selectedAirport"
        :airport-options="airportOptions"
        :select-airport-first="selectAirportFirst"
        @close="setActiveSearch(null)"
        @select="selectAirport"
      )

      LazyDestinationSelect(
        v-if="activeSearch === 'destination'"
        :select-destination-first="selectDestinationFirst"
        @search="search"
        @clear="setActiveSearch(null)"
        @next="setActiveSearch('date')"
      )

      LazyDateRangeSelect(
        v-if="activeSearch === 'date'",
        :class="{ lastminute }",
        :max-calendars="maxCalendars",
        :window-inner-width="windowWidth"
        :close-instead-of-next="isNarrower('lg') && (hasSelectedChildren || hasSearched)"
        @clear="setActiveSearch(null)"
        @start-date-change="onStartDateChange"
        @selected-date-range="setActiveSearch('guests')"
        @next="setActiveSearch('guests')"
      )

      LazyCharterSearchWindowGuests(
        v-if="activeSearch === 'guests'"
        :rooms="rooms"
        :passengers-full="overMaxNoOfPassengers"
        @clear="setActiveSearch(null)"
        @update="updateRooms"
        @search="search"
      )

  LazyCharterBookingErrorModal(
    :errors="tooManyInfants"
    :no-close-on-esc="false"
    :close-self="true"
  )
    template(#text)
      span {{ $t('charterSearchTooManyInfants') }}
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { evaluateRoomsHaveEmptyAgeValues } from '@/stores/utils/charter-packages'
import events from '@/constants/events'
import screenWidthMixin from '@/mixins/screen-width.mixin'

const ACTIVE_SEARCH_QUERY_PARAM_KEY = 's'

export default defineNuxtComponent({

  name: 'PackageSearchBar',

  mixins: [screenWidthMixin],

  props: {
    prefill: {
      type: Object,
      default: null
    },

    prefilledSelectedAirport: {
      type: [Object, String],
      default: null
    },

    doSearch: {
      type: Boolean,
      default: true
    },

    maxCalendars: {
      type: Number,
      default: 3
    },

    sv: {
      type: Boolean,
      default: false
    },

    hotel: {
      type: Object,
      default: null
    },

    airportOptions: {
      type: Array,
      default: null
    },

    dropdownDirection: {
      type: String,
      default: 'bottom'
    },

    preventScrollTop: {
      type: Boolean,
      default: false
    }
  },

  emits: ['search', 'selectAirport'],

  data () {
    return {
      selectedAirport: null,
      selectedStartDateRange: 0,
      selectDepartureDate: false,
      selectDestinationFirst: false,
      selectAirportFirst: false,
      selectChildAges: false,
      tooManyInfants: false,

      windowWidth: 0,

      timer: null,

      hasSelectedChildren: false,
      loading: false
    }
  },

  computed: {
    ...mapState(useRootStore, ['charterAirports']),

    ...mapState(useCharterPackagesStore, {
      destinations: 'destinations',
      lastminute: 'lastminute',
      selectedDateRangeStartDate: 'selectedDateRangeStartDate',
      selectedDateRangeEndDate: 'selectedDateRangeEndDate',
      selectedDateRangeDuration: 'selectedDateRangeDuration',
      rooms: 'rooms',
      getSearchOptions: 'getSearchOptions',
      overMaxNoOfPassengers: 'overMaxNoOfPassengers',
      peoplePerRoom: 'peoplePerRoom',
      categoriesForAirport: 'categoriesForAirport',
      hasSearched: 'hasSearched',
      getCharterSearchQuery: 'getCharterSearchQuery'
    }),

    ...mapState(useSearchWidgetsStore, {
      stagedDestinationsL1: 'stagedDestinationsL1',
      stagedDestinationsForAirport: 'stagedDestinationsForAirport',
      stagedDestinations: state => state.destinations.stagedDestinations,
      stagedCategories: state => state.destinations.stagedCategories,
      activeSearch: 'activeSearch'
    }),

    safePrefilledSelectedAirport () {
      if (this.prefilledSelectedAirport?.airport) {
        return this.prefilledSelectedAirport
      } else {
        return (this.charterAirports || []).find(x => x?.airport === this.prefilledSelectedAirport)
      }
    },

    isStartPage () {
      return this.$route.name === 'index'
    }
  },

  watch: {
    stagedDestinations () {
      this.loadSuggestions()
    },

    selectedDateRangeDuration () {
      this.loadSuggestions()
    },

    rooms: {
      deep: true,
      handler (v) {
        this.selectChildAges = evaluateRoomsHaveEmptyAgeValues(v)
      }
    },

    '$route.query.s' (activeSearch) {
      if ((activeSearch ?? null) !== this.activeSearch) {
        this.setActiveSearch(activeSearch)
      }
    }
  },

  async mounted () {
    const charterPackagesStore = useCharterPackagesStore()
    window.addEventListener('resize', this.resizeListener)
    this.windowWidth = window.innerWidth

    await charterPackagesStore.getDestinations()
    await this.prefillDestinations(this.prefill)

    if (this.prefill) {
      this.selectedStartDateRange = parseInt(this.prefill?.selectedStartDateRange) || this.selectedStartDateRange

      if (this.prefill?.rooms) {
        this.setRoomsWithCheck(this.prefill.rooms)
      }

      await this.getFilters()
      this.loadSuggestions()
    }

    this.$event.$on(events.packageSearchBarSearch, this.search)
  },

  beforeUnmount () {
    this.$event.$off(events.packageSearchBarSearch, this.search)
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    ...mapActions(useCharterPackagesStore, {
      setCalendarSuggestionsForDepartureDate: 'SET_CALENDAR_SUGGESTIONS_FOR_DEPARTURE_DATE',
      setSelectedRooms: 'SET_SELECTED_ROOMS',
      setRooms: 'SET_ROOMS',
      fetchCalendarSuggestionsByDepartureDate: 'fetchCalendarSuggestionsByDepartureDate',
      dispatchSearch: 'search',
      getFilters: 'getFilters',
      handleSearchQueryParams: 'handleSearchQueryParams'
    }),

    ...mapActions(useSearchWidgetsStore, {
      setActiveSearchState: 'SET_ACTIVE_SEARCH',
      setStagedDestinations: 'SET_STAGED_DESTINATIONS',
      prefillDestinations: 'prefillDestinations',
      handleCategories: 'handleCategories'
    }),

    ...mapActions(useMenuStore, {
      setAlertHidden: 'SET_ALERT_HIDDEN'
    }),

    resizeListener () {
      this.windowWidth = window.innerWidth
    },

    validateSearchParams () {
      if (!this.selectedAirport) { return 'airport' }
      if (!this.stagedDestinations?.length) { return 'destination' }

      const isValidNonFlex = this.selectedDateRangeStartDate && this.selectedDateRangeDuration
      const isValidFlex = this.selectedDateRangeStartDate && this.selectedDateRangeEndDate

      if (!isValidNonFlex && !isValidFlex) {
        return 'date'
      }

      if (evaluateRoomsHaveEmptyAgeValues(this.rooms)) { return 'guests' }

      if (this.peoplePerRoom.infants > this.peoplePerRoom.adults) { return 'tooManyInfants' }

      return null
    },

    handleSearchValidationError (error) {
      switch (error) {
        case 'startDate':
          this.selectDepartureDate = false
          this.$nextTick(() => {
            this.selectDepartureDate = true
          })
          this.setActiveSearch('date')
          break
        case 'guests':
          this.selectChildAges = false
          this.$nextTick(() => {
            this.selectChildAges = true
            this.setActiveSearch('guests')
          })
          break
        case 'tooManyInfants':
          this.tooManyInfants = true
          break
        default:
          this.setActiveSearch(error)
          break
      }
    },

    buildSearchParams (caller) {
      const params = {
        selectedAirport: this.selectedAirport,
        selectedCategories: this.stagedCategories,
        selectedDestinations: this.stagedDestinations,
        selectedDestinationsL1: this.stagedDestinationsL1,
        rooms: this.rooms,
        stopover: this.stopover,
        caller,
      }

      params.selectedDateRangeStartDate = this.selectedDateRangeStartDate
      params.selectedDateRangeEndDate = this.selectedDateRangeEndDate

      if (this.lastminute) {
        params.lastminute = 1
      }

      return params
    },

    search (caller) {
      const searchValidationError = this.validateSearchParams()
      if (searchValidationError) {
        return this.handleSearchValidationError(searchValidationError)
      }

      const params = this.buildSearchParams(caller)
      this.loading = true
      // NOTE Prevent double calling search with redirects to search page that calls search on mount
      if (this.doSearch) {
        this.dispatchSearch(params)
      }

      // NOTE search event needs to be emitted after dispatchSearch because dispatchSearch updates store values necessary for searchCalendar
      this.$emit('search', params)

      this.setActiveSearch(null)
    },

    setActiveSearch (search) {
      if (this.isNarrower('lg') && search) {
        this.setAlertHidden(true)
        document.body.classList.add('overflow-hidden')
      } else {
        this.setAlertHidden(false)
        document.body.classList.remove('overflow-hidden')
      }

      const nextActiveSearch = this.activeSearch === search ? null : search
      this.setActiveSearchState(nextActiveSearch)



      this.$router.replace({ query: {
        ...this.getCharterSearchQuery,
        [ACTIVE_SEARCH_QUERY_PARAM_KEY]: nextActiveSearch ?? undefined
      }})

      if (!this.preventScrollTop) {
        if (this.activeSearch && this.windowWidth <= 768) {
          window.document.body.classList.add('sideMenuActive')
          window.document.documentElement.classList.add('sideMenuActive')
        } else {
          window.document.body.classList.remove('sideMenuActive')
          window.document.documentElement.classList.remove('sideMenuActive')
        }
      }

      this.selectDestinationFirst = false
      this.selectAirportFirst = false

      if (search === 'guests') {
        this.hasSelectedChildren = true
      }

      if (search === 'guests' && this.selectChildAges) {
        this.setActiveSearchState('guests')
      } else if (search === 'date' && !this.selectedAirport) {
        this.selectAirportFirst = true
        this.setActiveSearchState('airport')
      } else if (search === 'date' && this.stagedDestinations.length === 0) {
        this.selectDestinationFirst = true
        this.setActiveSearchState('destination')
      }

      let pop = null

      this.$nextTick(() => {
        switch (this.activeSearch) {
          case 'airport':
            pop = this.$refs.charterWindowAirport
            break
          case 'destination':
            pop = this.$refs.charterWindowDestination
            break
          case 'date':
            pop = this.$refs.charterWindowDate
            break
          case 'guests':
            pop = this.$refs.charterWindowGuests
            break
        }

        if (this.windowWidth <= 768) {
          const crisp = document.querySelector('.crisp-client')

          if (crisp) {
            if (pop) {
              crisp.style.display = 'none'
            } else {
              crisp.style.display = null
            }
          }
        }

        if (!pop) {
          return
        }

        this.timer = window.setTimeout(() => {
          this.$nextTick(() => {
            const rect = pop?.getBoundingClientRect?.()
            const headerHeight = document.querySelector('.sticky-top').offsetHeight

            // document.documentElement.clientHeight instead of window.innerHeight because of iOS
            if (!rect || (rect.top - headerHeight >= 0 && rect.bottom <= document.documentElement.clientHeight)) {
              return
            }

            const scrollPos = this.$refs.searchcontainer.getBoundingClientRect().top + window.scrollY - headerHeight

            try {
              window.scrollTo({
                top: scrollPos,
                behavior: 'smooth'
              })
            } catch {
              window.scrollTo(0, scrollPos)
            }
          })
        }, 50)
      })
    },

    async loadSuggestions () {
      const charterPackagesStore = useCharterPackagesStore()
      charterPackagesStore.SET_CALENDAR_SUGGESTIONS({
        direct: [],
        flights: [],
      })

      if (!this.stagedDestinations?.length || !this.selectedAirport) {
        return
      }

      try {
        const options = this.getSearchOptions({
          airport: this.selectedAirport.airport,
          selectedDestinations: this.stagedDestinations,
          selectedDestinationsL1: this.stagedDestinationsL1,
        })

        delete options.departureDate
        delete options.stopOver
        delete options.homeDate

        await charterPackagesStore.getCalendarSuggestions(options)
      } catch (e) {
        this.$sentrySetContext('details', { data: e?.data || e })
        this.$sentryCaptureException('charterpackages/getCalendarSuggestions error', {
          level: 'warning',
          tags: {
            type: 'UX'
          }
        })
      }
    },

    async loadSuggestionsForDepartureDate (date) {
      this.setCalendarSuggestionsForDepartureDate({
        direct: [],
        flights: [],
      })

      if (!this.stagedDestinations?.length || !this.selectedAirport) {
        return
      }

      try {
        const options = this.getSearchOptions({
          airport: this.selectedAirport.airport,
          selectedDestinations: this.stagedDestinations,
          selectedDestinationsL1: this.stagedDestinationsL1,
        })

        delete options.stopOver
        if (date) {
          options.departureDate = date
        }

        await this.fetchCalendarSuggestionsByDepartureDate(options)
      } catch (e) {
        this.$sentrySetContext('data', JSON.stringify({ error: e?.data || e }))
        this.$sentryCaptureException('charterpackages/fetchCalendarSuggestionsByDepartureDate error', {
          level: 'warning',
          tags: {
            type: 'UX'
          }
        })
      }
    },

    onStartDateChange (date) {
      // Wait for end date to be reset before loading suggestions
      this.$nextTick(() => this.loadSuggestionsForDepartureDate(date))
    },

    updateRooms ({ rooms, emptyAgeValues }) {
      this.setRoomsWithCheck(rooms)
      this.selectChildAges = emptyAgeValues
    },

    setRoomsWithCheck (rooms) {
      if (JSON.stringify(rooms) === JSON.stringify(this.rooms)) {
        return
      }
      this.setRooms(rooms)
    },

    selectAirport ([airport, fullUpdate]) {
      this.$emit('selectAirport', airport)
      this.selectedAirport = airport

      if (fullUpdate) {
        if (Array.isArray(this.stagedCategories) && this.stagedCategories.length) {
          const stagedCategoriesAvailableForAirport = this.stagedCategories
            .filter(stagedCategory => this.categoriesForAirport.some(categoryForAirport => stagedCategory.id === categoryForAirport.id))
          this.handleCategories(stagedCategoriesAvailableForAirport)
        } else if (Array.isArray(this.stagedDestinations) && this.stagedDestinations.length) {
          this.setStagedDestinations(this.stagedDestinationsForAirport)
        }

        this.loadSuggestions()

        this.timer = window.setTimeout(() => {
          this.setActiveSearch('destination')
        }, 200)
      }
    }
  }
})
</script>

<style lang="scss">
@import "@/assets/scss/modules/charter/_charter-forms";

.blink {
  animation: blink 1.22s;
  background: $orange-transparent;

  &-title {
    background: transparent;
  }
}

@keyframes blink {
  0% {
    background: transparent;
  }

  20%,
  80% {
    background: $orange;
  }

  50% {
    background: $orange-transparent;
  }
}

/*! purgecss start ignore */
.package-search__dropdown-direction {
  &-top {
    top: 0;

    .charter-search__window {
      @media (min-width: ($lg + 1px)) {
        top: unset !important;
        bottom: 0 !important;
      }
    }
  }

  &_bottom {
    bottom: 0;

    .charter-search__window {
      @media (min-width: ($lg + 1px)) {
        top: 0 !important;
        bottom: unset !important;
      }
    }
  }
}
/*! purgecss end ignore */
</style>
